import * as React from 'react';
import {useEffect, ReactElement, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {Routes, Route, Navigate, RouteProps, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import {IStore} from 'redux/interface';
import {getAuthorizationAction} from 'redux/auth/authAsyncActions';
import {changeLocale} from 'redux/translation/translationAsyncActions';
import {firstAuthorizedRoute, RoutesUrl, routeTranslationKeys, TranslationKeys} from 'utils/constants/routeConstants';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {SystemLanguage} from 'utils/constants/constants';

import Backpack from 'pages/backpack';
import Callback from 'pages/callback';
import Chat from 'pages/chat';
import Content from 'pages/content';
import Error from 'pages/error';
import Home from 'pages/home';
import Layout from 'components/layout';
import Library from 'pages/library';
import Materials from 'pages/materials';
import UserSettings from 'pages/user-settings';
import Xpedition from 'pages/xpedition';
import Logout from 'pages/logout';
import {MobileView} from 'components/mobile-view/MobileView';
import {Bounce, ToastContainer} from 'react-toastify';
import {requestForToken} from 'utils/firebase/firebase';
import {addPushToken} from 'redux/api/push';
import Dialogues from 'pages/dialogues';
import Dialogue from '../dialogue';
import {XpeditionChat} from '../xpedition-chat/XpeditionChat';
import PublicContent from '../public-content';
import ArticlePage from '../article';
import {ArticleAuthors} from '../article-authors/ArticleAuthors';
import ArticleReferences from '../article-references';
import ArticleReference from '../article-reference';
import ArticleSection from '../article-section';
import ArticleSections from '../article-sections';
import {Resource} from '../resource/Resource';
import Journals from '../journals';
import JournalDialogue from '../../components/journal-chat';

const PrivateRoute: React.FunctionComponent<RouteProps> = (
  {
    children
  }
) => {
  const {auth0User} = useSelector((state: IStore) => state.auth);
  const [token, setToken] = useState('');
  const location = useLocation();

  useEffect(() => {
    const getToken = async () => {
      Notification.requestPermission()
        .then((permission) => addToken(permission))
        .catch((error) => {
          if (error instanceof TypeError) {
            Notification.requestPermission((permission) => {
              addToken(permission);
            });
          } else {
            throw error;
          }
        });
    };

    getToken();
  }, []);

  const addToken = async (permission: NotificationPermission) => {
    if (permission === 'granted') {
      const firebaseToken = await requestForToken();

      if (firebaseToken) {
        setToken(firebaseToken);
        if (auth0User) {
          addPushToken(firebaseToken).then(r => console.log(`Push token add result = ${r}`));
        }
      }
    }
  };

  return (
    auth0User
      ? (<Layout>{children as ReactElement}</Layout>)
      : (
        <Navigate
          to={{
            pathname: RoutesUrl.Home
          }}
          replace
          state={{from: location}}
        />
      )
  );
};

const App: React.FunctionComponent<RouteProps> = () => {
  const {
    translation: {
      locale,
      translations
    },
    auth: {
      userProfile
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const [isMobileView, setIsMobileView] = useState(false);

  useGetTranslations({
    translationKey: TranslationKeys.Common
  });
  useGetTranslations({
    translationKey: TranslationKeys.Toasts
  });
  useGetTranslations({
    translationKey: routeTranslationKeys[firstAuthorizedRoute]
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, [pathname]);

  useEffect(() => {
    if (userProfile && userProfile.language !== SystemLanguage.English) {
      dispatch(changeLocale(userProfile.language));
    }
  }, [userProfile, userProfile?.language]);

  useEffect(() => {
    // if (/Android|webOS|iPhone|iPad|iPod|Mac|Macintosh|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   setIsMobileView(true);
    // }
    // if (/Mac|Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints === 0) { // macbooks
    //   setIsMobileView(false);
    // }
    setIsMobileView(false);
    dispatch(getAuthorizationAction());
  }, []);

  return (
    <IntlProvider locale={locale} messages={translations[locale]} onError={(e) => console.log('IntlError', e)}>
      <ToastContainer
        role="alert"
        position="bottom-left"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        hideProgressBar
        limit={5}
        autoClose={30000}
        transition={Bounce}
      />
      <HelmetProvider>
        <Helmet>
          <html lang={locale}/>
        </Helmet>
      </HelmetProvider>

      {
        isMobileView
          ? <MobileView/>
          : <Routes>
            <Route path={RoutesUrl.Home} element={
              <Layout hideSeparator={true}>
                <Home/>
              </Layout>
            }>
            </Route>
            <Route path={RoutesUrl.Logout} element={
              <Layout>
                <Logout/>
              </Layout>
            }>
            </Route>
            <Route path={RoutesUrl.Callback} element={
              <Layout>
                <Callback/>
              </Layout>
            }>
            </Route>
            <Route path={RoutesUrl.Library} element={
              <PrivateRoute>
                <Library/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Materials} element={
              <PrivateRoute>
                <Materials/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Backpack} element={
              <PrivateRoute>
                <Backpack/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Chat} element={
              <PrivateRoute>
                <Chat/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.ChatRestart} element={
              <PrivateRoute>
                <Chat/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Xpeditions} element={
              <PrivateRoute>
                <Xpedition/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Xpedition} element={
              <PrivateRoute>
                <XpeditionChat/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Settings} element={
              <PrivateRoute>
                <UserSettings/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Content} element={
              <PrivateRoute>
                <Content/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Dialogues} element={
              <PrivateRoute>
                <Dialogues/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Dialogue} element={
              <PrivateRoute>
                <Dialogue/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.PublicContent} element={
              <Layout>
                <PublicContent/>
              </Layout>
            }/>
            <Route path={RoutesUrl.Article} element={
              <PrivateRoute>
                <ArticlePage/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.ArticleAuthors} element={
              <PrivateRoute>
                <ArticleAuthors/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.ArticleReferences} element={
              <PrivateRoute>
                <ArticleReferences/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.ArticleReference} element={
              <PrivateRoute>
                <ArticleReference/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.ReferenceAuthors} element={
              <PrivateRoute>
                <ArticleAuthors/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.ArticleSection} element={
              <PrivateRoute>
                <ArticleSection/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.ArticleSections} element={
              <PrivateRoute>
                <ArticleSections/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Resource} element={
              <PrivateRoute>
                <Resource/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Journals} element={
              <PrivateRoute>
                <Journals/>
              </PrivateRoute>
            }/>
            <Route path={RoutesUrl.Journal} element={
              <PrivateRoute>
                <JournalDialogue/>
              </PrivateRoute>
            }/>
            <Route path={'*'} element={
              <Layout>
                <Error/>
              </Layout>
            }>
            </Route>
          </Routes>
      }
    </IntlProvider>
  );
};

export default App;
