import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {IDialogueCard} from 'redux/dialogue/interfaces';

import {useLoadFile} from 'utils/hooks/useLoadFile';
import BotIcon from '../../assets/image/user-settings/BotDefaultAvatar.png';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import {DialogueModalWindow} from '../dialogue-modal-window/DialogueModalWindow';
import {dialogueAsyncActions, removeDialogue, setDialogueName} from '../../redux/pageAsyncActions';
import {ConfirmationModalWindow} from '../confirmation-modal-window/ConfirmationModalWindow';
import {FormattedDate} from 'react-intl';

import './styles.scss';

type Props = {
  card: IDialogueCard;
  onClick: () => void;
}

export const DialogueCard = ({
  card,
  onClick
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {
    media,
    getFileUrl
  } = useLoadFile();
  const [cardData, setCardData] = useState<IDialogueCard>(card);
  const [avatar, setAvatar] = useState<string>();
  const [isShowRenameDialogue, setShowRenameDialogue] = useState<boolean>(false);
  const [isShowDeleteDialogue, setShowDeleteDialogue] = useState<boolean>(false);

  useEffect(() => {
    setAvatar(cardData.avatar ? getFileUrl(cardData.avatar) : BotIcon);
  }, [media]);

  function handleOpenRenameDialogueModal() {
    setShowRenameDialogue(true);
  }

  function handleRenameDialogue(newDialogue: IDialogueCard) {
    dispatch(dialogueAsyncActions[setDialogueName](newDialogue));
    setCardData(newDialogue);
    setShowRenameDialogue(false);
  }

  function handleDeleteDialogue() {
    dispatch(dialogueAsyncActions[removeDialogue](cardData));
  }

  function handleCloseDialogue() {
    setShowRenameDialogue(false);
  }

  function handleOpenDeleteDialogue() {
    setShowDeleteDialogue(true);
  }

  function handleCloseDeleteDialogue() {
    setShowDeleteDialogue(false);
  }

  function renderBasicCard() {
    return <div className="dialogue-area">
      <div className="dialogue-area__card-place">
        <button className="dialogue-area__card" onClick={onClick}>
          <div className="dialogue-area__title">
            {cardData.name}
          </div>
          <div className="dialogue-area__content">
            <div className="dialogue-area__card-picture">
              <img src={avatar} role="presentation" alt="" className="dialogue-area__image"/>
            </div>
            <div className="dialogue-area__information">
              <div className="dialogue-area__description">
                {cardData.lastMessageText}
              </div>
              <div className="dialogue-area__category">
                {cardData.lastMessageDate != null
                  && <FormattedDate
                    value={cardData.lastMessageDate}
                    year="numeric"
                    month="long"
                    hour="numeric"
                    minute="numeric"
                    day="2-digit"
                  />}
              </div>
            </div>
          </div>
        </button>
        <div className="dialogue-area__bottom">
          <div className="dialogue-area__btn">
            <GritxButton
              title="Rename"
              variant={ButtonVariant.Primary}
              disabled={false}
              className="dialogue-area__button"
              onClick={handleOpenRenameDialogueModal}
            />
          </div>
          <div className="dialogue-area__btn">
            <GritxButton
              title="Delete"
              variant={ButtonVariant.Primary}
              disabled={false}
              className="dialogue-area__button"
              onClick={handleOpenDeleteDialogue}
            />
          </div>
        </div>
      </div>
      <DialogueModalWindow
        dialogueCard={card}
        title={'Rename the dialogue'}
        onSave={handleRenameDialogue}
        onClose={handleCloseDialogue}
        show={isShowRenameDialogue}
      />
      <ConfirmationModalWindow
        title={'Are you sure you want to delete the dialogue?'}
        onSave={handleDeleteDialogue}
        onClose={handleCloseDeleteDialogue}
        show={isShowDeleteDialogue}
      />
    </div>;
  }

  return renderBasicCard();
};
