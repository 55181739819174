import {IJournalStatistic, IJournalStatisticValue, IJournalStatisticValues} from './interfaces';
import {IAction} from '../interface';
import {InferActionsTypes} from '../helpers';

export const SET_JOURNAL_STATISTICS = 'SET_JOURNAL_STATISTICS';
export const setJournalStatistics = (statistics: IJournalStatistic[]): IAction<IJournalStatistic[]> => ({
  type: SET_JOURNAL_STATISTICS,
  payload: statistics
});

export const SET_JOURNAL_STATISTIC_VALUE = 'SET_JOURNAL_STATISTIC_VALUE';
export const setJournalStatisticValue = (statistics: IJournalStatisticValues[]): IAction<IJournalStatisticValues[]> => ({
  type: SET_JOURNAL_STATISTIC_VALUE,
  payload: statistics
});

const journalStatisticActions = {
  setJournalStatistics,
  setJournalStatisticValue
};

export type JournalStatsActionsType = InferActionsTypes<typeof journalStatisticActions>
