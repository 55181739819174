import {ThunkAction} from 'redux-thunk';
import {IStore} from '../interface';
import {Action} from 'redux';
import {
  getJournalStats, getJournalStatValue
} from '../pageAsyncActions';
import {clearErrorAction, finishLoadingAction, startLoadingAction} from '../status/actions';
import {errorHandler} from '../errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {getJournalStatsApi, getJournalStatValueApi} from '../api/journalStats';
import {setJournalStatistics, setJournalStatisticValue} from './actions';

export const loadJournalStatsAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getJournalStats;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getJournalStatsApi();

      dispatch(finishLoadingAction(actionKey));
      dispatch(setJournalStatistics(data));
    } catch (e) {
      console.log('loadJournalStatsAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadJournalStatsAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const loadJournalStatValueAction = (code: string): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getJournalStatValue;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getJournalStatValueApi(code);

      dispatch(finishLoadingAction(actionKey));
      dispatch(setJournalStatisticValue(data));
    } catch (e) {
      console.log('loadJournalStatValueAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadJournalStatValueAction,
        actionKey,
        error: e
      }));
    }
  }
});
