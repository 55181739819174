import {
  JournalStatsActionsType, SET_JOURNAL_STATISTIC_VALUE,
  SET_JOURNAL_STATISTICS
} from './actions';
import {
  IJournalStatistic,
  IJournalStatisticValues,
  IJournalStatsActionHandler,
  IJournalStatsInitialState
} from './interfaces';

const JournalStatsInitialState: IJournalStatsInitialState = {
  stats: [],
  values: {}
};

const setStats = ({
  state,
  payload
}: IJournalStatsActionHandler): IJournalStatsInitialState => {
  return {
    ...state,
    stats: payload as IJournalStatistic[]
  };
};

const setStatValues = ({
  state,
  payload
}: IJournalStatsActionHandler): IJournalStatsInitialState => {
  if (!payload || !payload.length) {
    return state;
  }
  const vals = payload as IJournalStatisticValues[];
  const code = vals[0].xpeditionStatsCode;
  const values = state.values;

  values[code] = vals.map(v => {
    return {
      xscCode: v.xscCode,
      xscName: v.xscName,
      value: v.value
    };
  });

  return {
    ...state,
    values
  };
};

const journalStatsReducerHandlers = new Map([
  [SET_JOURNAL_STATISTICS, setStats],
  [SET_JOURNAL_STATISTIC_VALUE, setStatValues]
]);

const journalStatsReducer = (state = JournalStatsInitialState, action: JournalStatsActionsType): IJournalStatsInitialState => {
  const handler = journalStatsReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IJournalStatsActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default journalStatsReducer;
