import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as IconUp} from '../../../assets/image/library/chevron-up.svg';
import {ReactComponent as IconDown} from '../../../assets/image/library/chevron-down.svg';
import {Collapse} from 'react-collapse';

import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../../redux/interface';
import {getJournalStats, getJournalStatValue, journalStatsAsyncActions} from '../../../redux/pageAsyncActions';

export const JournalStats: React.FunctionComponent = () => {
  const {
    journal_stats: {
      stats,
      values
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  useEffect(() => {
    dispatch(journalStatsAsyncActions[getJournalStats]());
  }, []);

  useEffect(() => {
    stats.forEach((stat) => {
      dispatch(journalStatsAsyncActions[getJournalStatValue](stat.code));
    });
  }, [stats]);

  const onClick = useCallback(
    () => setIsCollapseOpen(!isCollapseOpen),
    [isCollapseOpen]
  );

  return <div className="stats">
    <button className="stats__header" aria-expanded={isCollapseOpen} onClick={onClick}>
      <div className="stats__title">
        <FormattedMessage
          id={'gritx.journals.stats.header'}
          defaultMessage={'Statistics'}
        />
      </div>
      {isCollapseOpen ? <IconUp className="stats__arrow" /> : <IconDown className="stats__arrow" />}
    </button>
    <Collapse
      isOpened={isCollapseOpen}>
      <div className="stats__content">
        <>
          <div className="stats__values">
            {stats?.map(v => {
              return values[v.code] && values[v.code].length ? values[v.code].map((xsc => {
                return (
                  <div
                    key={v.code}
                    className="stats__value"
                    role="presentation">
                    <div className="stats__text">{v.name}{xsc.xscName && `, ${xsc.xscName}`}</div>
                    <div className="stats__number">{xsc.value}</div>
                  </div>
                );
              })) : <></>;
            })}
          </div>
        </>
      </div>
    </Collapse>
  </div>;
};
