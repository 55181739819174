import {ThunkAction} from 'redux-thunk';
import {IStore} from '../interface';
import {Action} from 'redux';
import {
  createJournal, editJournal,
  getJournals, removeJournal
} from '../pageAsyncActions';
import {clearErrorAction, finishLoadingAction, startLoadingAction} from '../status/actions';
import {
  setJournal,
  setJournals
} from './actions';
import {errorHandler} from '../errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {createJournalApi, editJournalApi, getJournalsApi} from '../api/journals';
import {IJournalDialogue} from './interfaces';
import {removeDialogueApi} from '../api/dialogue';

export const loadJournalsAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getJournals;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getJournalsApi();

      dispatch(finishLoadingAction(actionKey));
      dispatch(setJournals(data));
    } catch (e) {
      console.log('loadJournalsAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadJournalsAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const createJournalAction = (journal: IJournalDialogue): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = createJournal;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await createJournalApi(journal, locale);

      dispatch(setJournal(data));
      dispatch(loadJournalsAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('createJournalAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.CreateJournalAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const editJournalAction = (journal: IJournalDialogue): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = editJournal;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await editJournalApi(journal);
      dispatch(loadJournalsAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('editJournalAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.EditJournalAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const removeJournalAction = (journal: IJournalDialogue): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = removeJournal;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await removeDialogueApi(journal.dialogId);
      dispatch(loadJournalsAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('removeJournal', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.EditJournalAction,
        actionKey,
        error: e
      }));
    }
  }
});
