import axios, {AxiosResponse} from 'axios';
import {IJournalStatistic, IJournalStatisticValues} from '../journal-stats/interfaces';

export const getJournalStatsApi = (): Promise<AxiosResponse<IJournalStatistic[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/xpedition/journal/statistics`
  );
};

export const getJournalStatValueApi = (code: string): Promise<AxiosResponse<IJournalStatisticValues[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/xpedition/journal/statistics/${code}`
  );
};
