import {
  AuthActionsType,
  ERROR_LOGIN_SET,
  FINISH_AUTHORIZATION,
  SET_BOT_AVATARS,
  SET_USER,
  SET_USER_AVATARS,
  SET_USER_DATA,
  UPDATE_BOT_AVATAR,
  UPDATE_USER_AVATAR
} from './actions';
import {IAuthActionHandlerProps, IAuthInitialState, IAvatar, IUser} from './interfaces';
import {IUserProfile} from '@wholesalechange/chatcomponent';
import auth0Instance from 'utils/auth0/auth0Client';

const authInitialState: IAuthInitialState = {
  auth0Client: auth0Instance,
  auth0User: null,
  userProfile: null,
  botAvatars: null,
  userAvatars: null,
  isFinishAuthorization: false,
  relogin: false
};

function setUser({
  state,
  payload
}: IAuthActionHandlerProps): IAuthInitialState {
  return {
    ...state,
    auth0User: payload as IUser || null
  };
}

function setUserProfile({
  state,
  payload
}: IAuthActionHandlerProps): IAuthInitialState {
  return {
    ...state,
    userProfile: payload as IUserProfile || null
  };
}

function setBotAvatars({
  state,
  payload
}: IAuthActionHandlerProps): IAuthInitialState {
  return {
    ...state,
    botAvatars: payload as IAvatar[] || null
  };
}

function setUserAvatars({
  state,
  payload
}: IAuthActionHandlerProps): IAuthInitialState {
  return {
    ...state,
    userAvatars: payload as IAvatar[] || null
  };
}

function updateUserAvatarValue({
  state,
  payload
}: IAuthActionHandlerProps): IAuthInitialState {
  const data = payload as IAvatar;

  return {
    ...state,
    userAvatars: state.userAvatars?.map(avatar => {
      if (avatar.id === data.id) {
        return {
          ...avatar,
          fileUrl: data.fileUrl
        };
      }

      return avatar;
    }) || [data]
  };
}

function updateBotAvatarValue({
  state,
  payload
}: IAuthActionHandlerProps): IAuthInitialState {
  const data = payload as IAvatar;

  return {
    ...state,
    botAvatars: state.botAvatars?.map(avatar => {
      if (avatar.id === data.id) {
        return {
          ...avatar,
          fileUrl: data.fileUrl
        };
      }

      return avatar;
    }) || [data]
  };
}

function finishAuthorization({
  state
}: IAuthActionHandlerProps): IAuthInitialState {
  return {
    ...state,
    isFinishAuthorization: true
  };
}

const authReducerHandlers = new Map([
  [SET_USER, setUser],
  [ERROR_LOGIN_SET, setUser],
  [SET_USER_DATA, setUserProfile],
  [SET_BOT_AVATARS, setBotAvatars],
  [SET_USER_AVATARS, setUserAvatars],
  [UPDATE_USER_AVATAR, updateUserAvatarValue],
  [UPDATE_BOT_AVATAR, updateBotAvatarValue],
  [FINISH_AUTHORIZATION, finishAuthorization]
]);

const authReducer = (state = authInitialState, action: AuthActionsType): IAuthInitialState => {
  const handler = authReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IAuthActionHandlerProps>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default authReducer;

