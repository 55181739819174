import React, {ChangeEvent, FunctionComponent} from 'react';
import './styles.scss';
import {IRadioInput} from './interfaces';

interface IInputRadio {
  inputs: IRadioInput[]
  value: string
  onChange: (value: string) => void
  label: string
}

export const InputRadio: FunctionComponent<IInputRadio> = ({
  inputs,
  value,
  onChange,
  label
}: IInputRadio) => {
  function handleChangeRadio(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  return <div className="input-radio__wrap">
    <div className="input-radio__label">{label}</div>
    {
      inputs.map((radio) => {
        return <div key={`input-radio_${radio.id}`} className="input-radio">
          <input
            className='input-radio__field'
            id={`input-radio_${radio.id}`}
            type='radio'
            name={radio.name}
            value={radio.value}
            checked={value === radio.value}
            onChange={handleChangeRadio}
          />
          <label
            className='input-radio__label'
            htmlFor={`input-radio_${radio.id}`}>
            {radio.label}
          </label>
        </div>;
      })
    }
  </div>;
};
