import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';

import {useLoadFile} from 'utils/hooks/useLoadFile';
import {IStore} from 'redux/interface';
import BotIcon from 'assets/image/user-settings/BotDefaultAvatar.png';

import Loader from 'components/loader';

import {XpeditionItem} from './xpedition-item/XpeditionItem';
import './styles.scss';

interface IXpeditionList {
  onStart: (botId: number) => void
  onRemove: (dialogId: number) => void
}

export const XpeditionList: FunctionComponent<IXpeditionList> = ({
  onStart,
  onRemove
}: IXpeditionList) => {
  const {
    auth: {userProfile},
    dialogue: {xpeditions}
  } = useSelector((state: IStore) => state);
  const {getFileUrl} = useLoadFile();

  function handleStartXpedition(botId: number) {
    onStart(botId);
  }

  function handleRemoveDialog(dialogId: number) {
    onRemove(dialogId);
  }

  function getBotAvatar() {
    return userProfile?.dialogAvatarId ? getFileUrl(userProfile.dialogAvatarId) : BotIcon;
  }

  if (xpeditions.length === 0) {
    return <Loader nested/>;
  }

  const renderContent = () => {
    return <>
      {
        xpeditions.map(item => {
          return <XpeditionItem
            key={item.id}
            xpedition={item}
            onStart={handleStartXpedition}
            onRemove={handleRemoveDialog}
          />;
        })
      }
    </>;
  };

  return <div className="xpedition-list">
    <div className="xpedition-list__wrapper">
      <div className="xpedition-list__chat">
        <div className="xpedition-list__chat-toolbar">
          <div>
            <img
              src={getBotAvatar()}
              alt=""
              className="xpedition-list__bot-avatar"
            />
          </div>
        </div>
        <div className="xpedition-list__chat-wrapper">
          <div className="xpedition-list__start-message">
            <p>
              <FormattedMessage
                id={'gritx.xpedition.message'}
                defaultMessage={'Welcome to the place where all journeys start.'}
              />
            </p>
            <p>
              <FormattedMessage
                id={'gritx.xpedition.description'}
                defaultMessage={'Choose the adventure and let\'s dive in!'}
              />
            </p>
          </div>
        </div>
        <div className="xpedition-list__chat-toolbar"/>
      </div>

      <div className="xpedition-list__list">
        {renderContent()}
      </div>
    </div>
  </div>;
};
