import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'redux/interface';

import './styles.scss';
import Loader from '../loader';
import {IJournalDialogue} from '../../redux/journal/interfaces';
import JournalCard from '../journal-card';

type Props = {
  cards: IJournalDialogue[];
  showLoader?: boolean;
  onLoadMore?: (count: number) => void;
  onClickCard: (journal: IJournalDialogue) => void;
  checkedCards?: number[];
  disabledButtons?: boolean;
}

export const JournalsCardSection = ({
  showLoader = false,
  cards,
  onClickCard
}: Props): JSX.Element => {
  const {
    auth: {auth0User},
    content: {page}
  } = useSelector((state: IStore) => state);
  const [countCards, setCountCards] = useState(cards.length);
  const [totalElements, setTotalElements] = useState(0);
  const [cardsList, setCardsList] = useState<IJournalDialogue[]>(cards);

  useEffect(() => {
    if (page) {
      setTotalElements(page.totalElements);
    }
  }, [page]);

  useEffect(() => {
    setCardsList(cards);
    setCountCards(cards.length);
  }, [cards]);

  function handleClickCard(journal: IJournalDialogue) {
    return () => {
      onClickCard(journal);
    };
  }

  return <section className="journals-card-section">
    {showLoader ? <Loader/> : <ul className="journals-card-section__list">
      {
        cardsList.map((card, i) => {
          return i < countCards
            ? <li key={card.dialogId} className="journals-card-section__item">
              <JournalCard
                card={card}
                onClick={handleClickCard(card)}/>
            </li> : null;
        })
      }
    </ul> }
  </section>;
};
