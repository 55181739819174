export enum RoutesUrl {
  Home = '/',
  Logout = '/logout',
  Library = '/library',
  Materials = '/materials',
  Backpack = '/backpack',
  Chat = '/chat',
  ChatRestart = '/chat/:restart?',
  Xpeditions = '/xpedition',
  Xpedition = '/xpedition/:xpeditionId/:restart?',
  Settings = '/settings',
  Content = '/content',
  PublicContent = '/public-content',
  Callback = '/callback',
  Dialogues = '/dialogues',
  Dialogue = '/dialogue/:dialogueId',
  Article = '/article/:articleId',
  ArticleAuthors = '/article/authors/:articleId',
  ReferenceAuthors = '/article/reference/authors/:articleId/:referenceId',
  ArticleReferences = '/article/references/:articleId',
  ArticleReference = '/article/reference/:articleId/:referenceId',
  ArticleSection = '/article/section/:articleId/:sectionIndex',
  ArticleSections = '/article/sections/:articleId',
  Resource = '/resource',
  Journals = '/journals',
  Journal = '/journal'
}

export enum TranslationKeys {
  Library = 'library',
  Materials = 'materials',
  Backpack = 'backpack',
  Chat = 'chat',
  Settings = 'user-settings',
  Content = 'content-page',
  Error = 'error',
  Footer = 'footer',
  Common = 'common',
  Home = 'home',
  Toasts = 'toasts',
  Dialogues = 'dialogues',
  Dialogue = 'dialogue',
  Article = 'article',
  Resource = 'resource',
  Journals = 'journals'
}

export const firstAuthorizedRoute = RoutesUrl.Chat;

export const routeTranslationKeys: { [key: string]: string } = {
  [RoutesUrl.Library]: TranslationKeys.Library,
  [RoutesUrl.Materials]: TranslationKeys.Materials,
  [RoutesUrl.Backpack]: TranslationKeys.Backpack,
  [RoutesUrl.Chat]: TranslationKeys.Chat,
  [RoutesUrl.ChatRestart]: TranslationKeys.Chat,
  [RoutesUrl.Xpeditions]: TranslationKeys.Chat,
  [RoutesUrl.Xpedition]: TranslationKeys.Chat,
  [RoutesUrl.Settings]: TranslationKeys.Settings,
  [RoutesUrl.Content]: TranslationKeys.Content,
  [RoutesUrl.Dialogues]: TranslationKeys.Dialogues,
  [RoutesUrl.Dialogue]: TranslationKeys.Dialogue,
  [RoutesUrl.Resource]: TranslationKeys.Resource,
  [RoutesUrl.Journals]: TranslationKeys.Journals
};
