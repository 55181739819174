import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {TimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TextField} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import cn from 'classnames';

import {isRequiredValidator} from 'utils/helpers/validators';

import './styles.scss';

interface IInputTime {
  className?: string
  label?: string
  value?: Date
  placeholder?: string
  required?: boolean
  onChange?: (value: Date) => void
  onBlur?: (value: Date) => void
  locale?: string
}

const inputErrorTypes = {
  required: 'required'
};

const errorMessage: { [type: string]: { id: string; defaultMessage: string } } = {
  [inputErrorTypes.required]: {
    id: 'gritx.common.errors.required',
    defaultMessage: 'This field cannot be blank'
  }
};

function validateValue(value: string, required?: boolean): string[] {
  let errors = [] as string[];

  if (required && !isRequiredValidator(value)) {
    errors = [inputErrorTypes.required];
  }

  if (!required && value.length === 0) {
    errors = [];
  }

  return errors;
}

export const InputTime: React.FunctionComponent<IInputTime> = ({
  label,
  required = false,
  onChange,
  onBlur,
  className = '',
  placeholder,
  value,
  locale,
  ...props
}: IInputTime) => {
  const [errors, setErrors] = useState<string[]>([]);

  function handleChange(date: Dayjs | null) {
    setErrors([]);
    if (onChange && date) {
      onChange(date.toDate());
    }
  }

  return <div className={`input-time ${className}`}>
    <label className={'input-time__label'}>
      <span className={'input-time__label-text'}>
        {label}
        {required && <span className={'input-time__required'}>*</span>}
      </span>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <TimePicker
          disabled={false}
          value={value}
          onChange={(newValue: Dayjs | null) => {
            handleChange(newValue);
          }}
          InputProps={{
            className: cn('input-time__field'),
            onBlur: (e) => {
              setErrors(validateValue(e.target.value, required));
            }
          }}
          renderInput={(params) => <TextField {...params}/>}
        />
      </LocalizationProvider>
    </label>
    {
      errors.map((e, i) => <span key={i} className="input-time__error">
        {errorMessage[e] && <FormattedMessage {...errorMessage[e]}/>}
      </span>)
    }
  </div>;
};
